<!--
.. Modified by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
  <v-container fluid>
    <new-edit-sheet />
    <delete-dialog />
    <v-row align="center" justify="space-between" no-gutters>
      <v-col class="grow">
        <div class="headline">
          <v-icon large class="mr-2">mdi-dots-grid</v-icon>
          Projects
        </div>
      </v-col>
      <v-col class="shrink">
        <v-btn small color="info" class="mb-2" @click="createEditShow()">
          <v-icon small class="mr-1">mdi-plus</v-icon>
          New Project
        </v-btn>
      </v-col>
    </v-row>
    <v-alert outlined dismissible icon="mdi-dots-grid" prominent type="info">
      <div>
        <strong>Projects in unstruct.ai: </strong>
        <ul>
          <li>Allow teams to have their own settings and integrations, offering flexibility in on-call schedules, services, and plugins.</li>
          <li>Enable the definition of custom TTP (Tactics, Techniques, and Procedures) patterns for each project, which UnStruct monitors in your IAO channels and documents.</li>
        </ul>
      </div>
    </v-alert>
    <v-row no-gutters>
      <v-col>
        <v-card elevation="0">
          <v-card-title>
            <v-text-field
              v-model="q"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              clearable
            />
          </v-card-title>
          <v-row>
          <v-col cols="12" sm="6" md="4" v-for="item in items" :key="item.id">
            <v-card class="ma-4 elevation-2">
              <!-- Item Name and Quick Link -->
              <v-card-title class="justify-space-between">
                <div>
                  <v-icon large left color="primary">mdi-dots-grid</v-icon>
                  {{ item.name }}
                </div>
                <router-link
                  :to="{ name: 'ProjectSettings', query: { project: item.name } }"
                  class="project-settings-link"
                >
                  <v-chip color="tips" text-color="white">
                    <v-icon left>mdi-cogs</v-icon>
                    Project Settings
                    <v-icon right>mdi-arrow-right</v-icon>
                  </v-chip>
                </router-link>
              </v-card-title>
              <!-- Item Details -->
              <v-card-text class="pt-3">
                <div class="my-2">
                  <v-icon left color="primary">mdi-text-box</v-icon>
                  {{ item.description }}
                </div>
                <div class="my-2" v-if="item.default">
                  <v-icon left color="secondary">mdi-star</v-icon>
                  Default
                </div>
                <div class="my-2">
                  <v-icon left color="purple lighten-2">mdi-clock-time-four-outline</v-icon>
                  Annual Employee Cost: ${{ item.annual_employee_cost }}
                </div>
                <div class="my-2">
                  <v-icon left color="error">mdi-strategy</v-icon>
                  TTP Monitoring Patterns: {{ item.custom_ttp_patterns.length }}
                </div>
                <div class="my-2">
                  <v-icon left color="warning">mdi-clock-time-four-outline</v-icon>
                  Business Year Hours: {{ item.business_year_hours }}
                </div>
                <div class="my-2">
                  <v-icon left color="blue-grey">mdi-email</v-icon>
                  Owner Email: {{ item.owner_email }}
                </div>
                <div class="my-2">
                  <v-icon left color="teal">mdi-message-text-outline</v-icon>
                  Owner Conversation: #{{ item.owner_conversation }}
                </div>
              </v-card-text>
              <!-- Actions -->
              <v-card-actions>
                <router-link :to="{ name: 'ProjectSettings', query: { project: item.name } }">
                  <v-btn small dark color="primary">
                    <v-icon small left>mdi-cogs</v-icon>
                    Project Settings
                  </v-btn>
                </router-link>
                <v-spacer></v-spacer>
                <v-btn class="ml-2" text color="primary" @click="createEditShow(item)">Edit</v-btn>
                <v-btn color="error" text @click="removeShow(item)">Delete</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields"
import { mapActions } from "vuex"

import DeleteDialog from "@/project/DeleteDialog.vue"
import NewEditSheet from "@/project/NewEditSheet.vue"

export default {
  name: "ProjectTable",

  components: {
    DeleteDialog,
    NewEditSheet,
  },

  data() {
    return {
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Description", value: "description", sortable: false },
        { text: "Annual Employee Cost", value: "annual_employee_cost", sortable: false },
        { text: "Business Year Hours", value: "business_year_hours", sortable: false },
        { text: "", value: "data-table-actions", sortable: false, align: "end" },
      ],
    }
  },

  computed: {
    ...mapFields("project", [
      "table.options.q",
      "table.options.page",
      "table.options.itemsPerPage",
      "table.options.sortBy",
      "table.options.descending",
      "table.loading",
      "table.rows.items",
      "table.rows.total",
    ]),
  },

  created() {
    this.getAll()

    this.$watch(
      (vm) => [vm.page],
      () => {
        this.getAll()
      }
    )

    this.$watch(
      (vm) => [vm.q, vm.itemsPerPage, vm.sortBy, vm.descending],
      () => {
        this.page = 1
        this.getAll()
      }
    )
  },

  methods: {
    ...mapActions("project", ["getAll", "createEditShow", "removeShow"]),
  },
}
</script>

<style scoped>
.settings-chip {
  transition: all 0.3s ease;
  min-width: 120px;
  padding-left: 12px;
  padding-right: 12px;
}

.settings-chip:hover {
  background-color: #00897b;
}

.settings-icon {
  transition: transform 0.3s ease-in-out;
}

.settings-chip:hover .settings-icon {
  transform: translateX(5px);
}

.project-settings-link {
  cursor: pointer !important;
  text-decoration: none;
}
.v-chip {
  cursor: pointer;
}

</style>
