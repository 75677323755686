import { render, staticRenderFns } from "./CustomTtpFromSignals.vue?vue&type=template&id=b4c655e6"
import script from "./CustomTtpFromSignals.vue?vue&type=script&lang=js"
export * from "./CustomTtpFromSignals.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports