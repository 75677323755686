<!--
.. Added by Kishore Jalleda
.. full list of modifications at https://github.com/unstructai
.. copyright: (c) 2023 Kishore Jalleda
.. author:: Kishore Jalleda <kjalleda@gmail.com>
-->
<template>
    <v-container>
      <v-row no-gutter style="margin-bottom: 20px;">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip v-on="on" v-bind="attrs" color="error" text-color="white">
              <v-icon left>mdi-file-plus</v-icon>
              TTP Patterns
            </v-chip>
          </template>
          <span>
            Add your Tactics, Techniques, and Procedures (TTP) Patterns here.
            <br />
            unstruct.ai will monitor these patterns in your IAO channels and documents.
            <br />
            If a signal definition is configured, a case will be automatically created.
          </span>
        </v-tooltip>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn small icon @click="addPattern()" v-on="on">
              <v-icon large color="secondary">mdi-playlist-plus</v-icon>
            </v-btn>
          </template>
          <span>
            <v-icon x-small class="mr-1">mdi-playlist-plus</v-icon>
            Add Pattern
          </span>
        </v-tooltip>
      </v-row>
      <span v-for="(pattern, idx) in ttpPatterns" :key="idx">
        <v-row align="center" dense>
          <v-col cols="12" sm="1">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn small icon @click="removePattern(idx)" v-on="on">
                  <v-icon>remove</v-icon>
                </v-btn>
              </template>
              <span>Remove Pattern</span>
            </v-tooltip>
          </v-col>

          <v-col cols="12" sm="11">
            <v-row>
              <v-col v-for="(token, i) in pattern" :key="i">
                <v-text-field
                  label="Token"
                  :value="token.LOWER"
                  @input="updateToken(idx, i, $event)"
                />
              </v-col>
            </v-row>
            <v-btn small color="blue lighten-1" @click="addToken(idx)">
              <v-icon x-small class="mr-1">mdi-playlist-plus</v-icon>
              Add Token
            </v-btn>
          </v-col>
        </v-row>
      </span>
    </v-container>
  </template>


<script>
import { cloneDeep } from "lodash"

export default {
  name: "CustomTtpFromSignals",

  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ttpPatterns: {
      get() {
        return this.value || [];
      },
      set(newPatterns) {
        this.$emit("input", newPatterns);
      },
    }
  },

  methods: {
    addPattern() {
      if (!this.ttpPatterns) {
        this.ttpPatterns = [];
      }
      const newPattern = cloneDeep(this.ttpPatterns);
      newPattern.push([{ LOWER: '' }]);
      this.ttpPatterns = newPattern;
    },
    removePattern(indexToRemove) {
      const newPattern = cloneDeep(this.ttpPatterns);
      newPattern.splice(indexToRemove, 1);
      this.ttpPatterns = newPattern;
    },
    addToken(patternIndex) {
      const newPattern = cloneDeep(this.ttpPatterns);
      newPattern[patternIndex].push({ LOWER: '' });
      this.ttpPatterns = newPattern;
    },
    updateToken(patternIndex, tokenIndex, eventValue) {
      const newPattern = cloneDeep(this.ttpPatterns);
      newPattern[patternIndex][tokenIndex].LOWER = eventValue;
      this.ttpPatterns = newPattern;
    },
  },
}
</script>
